<template>
<v-container>
    <v-row class="d-flex align-center mx-1">
        <v-col cols="auto">
          <v-icon
            large
            class="mb-4"
          >mdi-map-marker-multiple</v-icon>
          <span class="text-h2 ml-2">Global Map</span>
        </v-col>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              class="ml-auto mr-4"
              @click="showFilters = !showFilters"
              :color="showFilters ? 'primary' : 'darkgrey'"
              fab
              small
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Toggle Filters</span>
        </v-tooltip>
    </v-row>
    <!-- Search bar-->
    <v-row :class="showFilters ? 'mb-n10' : 'mb-n8'" style="width: 100%">
        <!-- Search bar full width-->
        <v-col cols="12">
            <v-text-field
                autofocus
                v-model="search"
                outlined
                label="Search"
                append-icon="mdi-magnify"
            >
                <template #append>
                    <v-icon
                        v-if="search"
                        color="primary"
                        class="mr-2"
                        @click="search = ''"
                    >mdi-close</v-icon>
                </template>
            </v-text-field>
        </v-col>
    </v-row>
    <!-- Filters -->
    <v-row v-if="showFilters" style="width: 100%">
        <!-- Filter by campaigns -->
        <v-col cols="12" md="4" lg="3">
            <v-select
                v-model="selectedCampaigns"
                :items="campaigns"
                :item-text="item => item.n"
                item-value="id"
                label="Filter by Campaigns"
                outlined
                multiple
                return-object
                
            />
        </v-col>
        <!-- Filter by bursts -->
        <v-col cols="12" md="4" lg="3">
            <v-select
                v-model="selectedBursts"
                :items="bursts"
                :item-text="item => item.n"
                item-value="id"
                label="Filter by Bursts"
                outlined
                multiple
                return-object
            />
        </v-col>
        <!-- Filter by screen -->
        <v-col cols="12" md="4" lg="3">
            <v-select
                v-model="selectedScreens"
                :items="screens"
                :item-text="item => item.n + ' - ' + item.mo.fn"
                item-value="id"
                label="Filter by Screens"
                outlined
                multiple
                return-object
            />
        </v-col>
        <!-- Filter by delivery type -->
        <v-col cols="12" md="4" lg="3">
            <v-select
                v-model="selectedDeliveryTypes"
                :items="deliveryTypes"
                :item-text="item => item.name"
                item-value="id"
                label="Filter by Delivery Types"
                outlined
                multiple
                return-object
            />
        </v-col>
        <!-- Filter by media owner -->
        <v-col class="mt-n8" cols="12" md="4" lg="3">
            <v-select
                v-model="selectedMediaOwners"
                :items="mediaOwner"
                :item-text="item => item.fn"
                item-value="id"
                label="Filter by Media Owners"
                outlined
                multiple
                return-object
            />
        </v-col>
    </v-row>
    <!-- Map -->
    <v-row class="mx-auto" style="width: 100%; height: 100vh">
        <l-map
            ref="globalMap"
            :zoom="zoomLevel"
            :center="centerLocation" 
            style="z-index:1"
        >
            <l-tile-layer
                :url="url"
                :attribution="attribution"
            />
                <span
                    v-for="(cbs, indexC) in filteredData"
                    :key="indexC"
                >
                <v-marker-cluster>
                    <l-marker
                        v-for="(location, index) in cbs.lz"
                        :key="index"
                        :lat-lng="[location.lat, location.lon]"
                        :icon="mapMarkerIcon(cbs.cbs.cb.c.n)"
                    >
                        <l-popup
                            :lat-lng="[location.lat, location.lon]"   
                        >
                            <span class="white--text d-flex justify-center">{{ cbs.cbs.cb.c.n }}</span>
                            <span class="white--text d-flex justify-center">{{ cbs.cbs.s.mo.fn }}</span>
                            <span class="white--text d-flex justify-center">{{ cbs.cbs.s.n }}</span>
                            <br/>
                            <span class="white--text d-flex justify-center">{{ location.n }}</span>
                            <br/>
                            <span class="white--text d-flex justify-center"><a :href="'https://www.google.com/maps/search/?api=1&query=' + location.lat + ',' + location.lon"  target="_blank">Open in Google Maps</a></span>
                        </l-popup>
                    </l-marker>
                </v-marker-cluster>
                </span>
        </l-map>
    </v-row>
</v-container>
</template>
<script>
    // Controllers
    import CampaignController from "@/services/controllers/Campaign"
    // Map
    import L, { latLng } from 'leaflet'
    import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet'
    import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
    // Mixins
    import collectionSearch from '@/mixins/collection/search'

export default {
    name: 'CampaignMapView',
    components: {
        latLng,
        L,
        LIcon,
        LMap,
        LPopup,
        LTileLayer,
        LMarker,
        "v-marker-cluster": Vue2LeafletMarkerCluster
    },
    data: () => ({
        // Zoom / Center
        // Campaign Burst Screen Locations
        locationtableData: [],
        zoomLevel:10,
        centerLocation: [51.5, -0.1],
        // Map Style
        url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
        // Marker locations
        markerLatLng: [51.52463861618491, -0.11485325878846236],
        // Filters
        selectedScreen: null,
        selectedScreens: [],
        selectedBursts: [],
        selectedCampaigns: [],
        selectedDeliveryTypes: [],
        selectedMediaOwners: [],
        search: '',
        showFilters: false,

        // Search Params
        searchParams: [
            { name: 'lz.n', weight: 1 },
            { name: 'cbs.cb.c.n', weight: 2 },
            { name: 'cbs.cb.c.s.n', weight: 1.5 },
            { name: 'cbs.cb.c.s.mo.n', weight: 1 },
            { name: 'cbs.cb.dt.name', weight: 0.5 },
        ],
    }),
    mounted() {
        const map = this.$refs.globalMap.mapObject
        map.addControl(new window.L.Control.Fullscreen())
       
    },
    async created() {
        await this.getCampaignLocationsList().then(() => {
            if (this.$route.query.cid) {
                // FInd the campaign object from the locationtableData where the id matches the cid in the url
                const campaign = this.locationtableData.find(item => item.cbs.cb.c.id === parseInt(this.$route.query.cid))
                if(campaign !== undefined){
                    this.selectedCampaigns.push(campaign.cbs.cb.c)
                } else{
                    this.$root.$emit('snackbarWarning', 'Feature available only on live and pending campaigns')
                }
            }
        })
    },
    computed: {
        // Function to return all the bursts available in locationtableData
        bursts() {
            return this.locationtableData.map(cbs => cbs.cbs.cb)
        },
        screens(){
            return this.locationtableData.map(cbs => cbs.cbs.s)
        },
        bursts(){
            return this.locationtableData.map(cbs => cbs.cbs.cb)
        },
        campaigns(){
            return this.locationtableData.map(cbs => cbs.cbs.cb.c)
        },
        deliveryTypes(){
            return this.locationtableData.map(cbs => cbs.cbs.cb.dt)
        },
        mediaOwner(){
            return this.locationtableData.map(cbs => cbs.cbs.s.mo)
        },
        filteredData() {
            let filteredData = this.locationtableData
            // Delivery Types
            if (this.selectedDeliveryTypes.length > 0) {
                filteredData = filteredData.filter(cbs => this.selectedDeliveryTypes.map(dt => dt.id).includes(cbs.cbs.cb.dt.id))
            }
            // Media Owners
            if (this.selectedMediaOwners.length > 0) {
                filteredData = filteredData.filter(cbs => this.selectedMediaOwners.map(mo => mo.id).includes(cbs.cbs.s.mo.id))
            }
            // Bursts
            if (this.selectedBursts.length > 0) {
                filteredData = filteredData.filter(cbs => this.selectedBursts.map(b => b.id).includes(cbs.cbs.cb.id))
            }
            // Campaigns
            if (this.selectedCampaigns.length > 0) {
                filteredData = filteredData.filter(cbs => this.selectedCampaigns.map(c => c.id).includes(cbs.cbs.cb.c.id))
            }
            // Screens
            if (this.selectedScreens.length > 0) {
                filteredData = filteredData.filter(cbs => this.selectedScreens.map(s => s.id).includes(cbs.cbs.s.id))
            }
            return collectionSearch(filteredData, this.searchParams, this.search, 0.15, true)
        }
    },
    methods: {
        // Get Campaign Locations
        async getCampaignLocationsList () {
            await CampaignController.getCampaignLocations(true) // true = only active screens
            .then((res) => {
                this.locationtableData = res.data
            })
            .catch((err) => {
                this.$root.$emit('snackbarError', ''+ err.response.data.message)
            })
        },
        // Map Marker Color
        mapMarkerColor (campaignName)
        {
            const iconColor = this.intToRGB(this.hashCode(campaignName));
            const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path fill="#`+iconColor+`" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>`;
            return svgString;
        },

        // alternate marker that looks a little like a screen
        mapMarkerColorScreen (campaignName) {
            const iconColor = this.intToRGB(this.hashCode(campaignName));
            const svgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
                <rect x="0" y="0" width="25" height="15" fill="#`+iconColor+`" stroke="black" stroke-width="1"/>
                <line x1="15" y1="15" x2="15" y2="25" stroke="black" stroke-width="2"/>
                </svg>`;
            return svgString;
        },

        mapMarkerIcon(campaignName) {
            return L.icon({
                iconUrl: `data:image/svg+xml;base64,${btoa(this.mapMarkerColor(campaignName))}`,
                iconSize: [35, 35],
                iconAnchor: [16, 35],
                popupAnchor: [1, -15]
            });
        },

        hashCode(str) { // java String#hashCode
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        } ,

        intToRGB(i){
            var c = (i & 0x00FFFFFF)
                .toString(16)
                .toUpperCase();

            return "00000".substring(0, 6 - c.length) + c;
        }

	}
}
</script>
<style scoped>
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  *::v-deep .leaflet-popup-content-wrapper {
    background-color: rgb(30,30,30) !important;
  }
</style>